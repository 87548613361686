.login-main {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom right, #6e6e6e, rgb(184, 184, 184));
  display: flex;
  justify-content: center;

  .login-center {
    width: 30%;
    align-self: center;
    background-color: #fff;
    padding: 100px;

    .login-form {
      max-width: 100%;

      .login-form-button {
        width: 100%;
      }
    }
  }
}
