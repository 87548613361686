html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

.main {
  margin: 0 auto;
  width: 80%;
  background-color: #fff;
  margin-bottom: 150px;
  padding: 30px;
}

.footer-fixed {
  height: 100px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 40px;

  .ui-button-raised {
    width: 200px;

    &:first-of-type {
      margin-right: 50px;
    }
  }
}
